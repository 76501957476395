.product-image img {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .product-image img {
        padding-bottom: 20px;
    }
}

.product-image {
    position: relative;
    padding: 0px 10px;
}

.content-img {
    position: absolute;
    bottom: 8%;
    text-align: center;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1200px) and (min-width: 600px) {
    .content-img {
        bottom: 0%;
    }
}

@media only screen and (max-width: 600px) {
    .content-img {
        bottom: 0%;
    }
}

.content-img h1 {
    font-size: 30px;
    font-weight: bold;
}

.content-img button {
    border-radius: 30px;
}

.content-img button:hover {
    background-color: bisque;
}

h1.product-title {
   
}

.productcompny {
    padding: 40px 0px;
}

.sliderimport .carousel-caption {
    left: 9% !important;
}

.product-para p {
    line-height: 32px;
    font-size: 20px;
    text-align: center;
    color: #000;
    font-family: "Colus Regular";
   
}
@media only screen and (max-width: 576px) {
    .product-para p {
        
        font-size: 15px;
    
    }}
.product-para {
    padding-bottom: 30px;
}

.nonoil-img {
    position: relative;
}

.nonoil-img h1 {
    font-weight: 800;
    font-size: 25px;
    position: absolute;
    bottom: -15px;
    text-align: center;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1200px) and (min-width: 767px) {
    .nonoil-img h1 {

        font-size: 16px;

    }
}