@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital@1&display=swap');

body {
  margin: 0;
  font-family: 'English Font Family', 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html[lang='ar'] body {
  font-family: 'Arabic Font Family', 'PT Serif', serif;
  /* Replace with your desired Arabic font */
}

code {}

body {
  line-height: 25px;
  letter-spacing: 0px;
  font-weight: 400;
  font-style: normal;

  color: black;
  font-size: 15px;
  opacity: 1;
  visibility: visible;
}

p {}

.row {
  padding: 0px;
  margin: 0px;
}

.col-md-6 {
  padding: 0px;
  margin: 0px;
}

.col-md-2 {
  padding: 0px;
  margin: 0px;
}