select {

    appearance: none;

 background-color: #3ab24a;
    border: none;
 padding: 0px 2px;
    margin: 0;
    
    font-family: inherit;
    font-size: 14px;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    color: white;
}

select::-ms-expand {
    color: white;
}
select::-ms-expand:hover{
    background-color: #3ab24a; color: white;
}
.langclass{
    
margin-top: 20px;
}