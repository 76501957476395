@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital@1&display=swap');

ul {
  padding-left: 0px;
}

.logo img {
  width: 280px;

  background-color: #fff;
}

@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .logo img {
    width: 166px;
  }
}

@media only screen and (max-width: 1349px) and (min-width: 1200px) {
  .logo img {
    width: 220px;
  }
}
@media only screen and (max-width: 576px) and (min-width: 360px) {
  .logo img {
    width: 250px;
  }
}

@media only screen and (max-width: 360px) {
  .logo img {
    width: 150px;
  }
}

.navbar-brand {
  padding: 25px 0px 25px 25px;
}



.logo .logo2 {
  width: 250px;
}

.logo .logo1 {
  width: 45px;
}

@media (max-width: 600px) {
  .logo .logo2 {
    width: 200px;
  }
}

@media (max-width: 600px) {
  .logo .logo1 {
    width: 30px;
  }
}

.carousel-caption {
  background-color: transparent !important;
}

.navlinks .nav-link {
  color: #000;
  font-weight: 600;
  font-size: 12px;
  padding-top: 20px;
}

.navlinks a {
  color: #000;
  font-weight: 600;
  font-size: 14px;
  padding-top: 14px;
  text-decoration: none;
  /* margin-left: 5px; */
  padding: 20px 10px;
}

@media (max-width: 767px) {
  .navlinks a {

    color: white;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .navlinks a {
    font-size: 8px;
    padding: 20px 5px
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
  .navlinks a {
    font-size: 12px;
    padding: 20px 5px;
  }
}


.navlinks a.active,
.navlinks a.hover {
  color: #cec70b;
}

.navbar-toggler {
  border: 0px;
  background: #3ab54a;
  border-radius: inherit;
  padding: 5px;
  color: white;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.sidebar {
  position: fixed;
  top: 56px;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: #f8f9fa;
  transition: all 0.3s ease-in-out;
  z-index: 9999;
}

.sidebar.show {
  left: 0;
}

.content {
  padding: 20px;
  margin-top: 56px;
}




@media (max-width: 767px) {
  .navbar-expand-lg .navbar-collapse {
    position: fixed;
    top: 93px;
    left: -300px;
    width: 200px;
    height: 100%;
    
    background-color: #36b549;

    transition: all 0.3s ease-in-out;
    z-index: 9999;
  }
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
  .navbar-expand-lg .navbar-collapse {
    position: fixed;
    top: 93px;
    left: -300px;
    width: 200px;
    height: 100%;
    
    background-color: #36b549;

    transition: all 0.3s ease-in-out;
    z-index: 9999;
  }
}

@media only screen and (max-width: 576px) {
  .navbar-expand-lg .navbar-collapse {
    position: fixed;
    top: 75px;
    left: -300px;
    width: 200px;
    height: 100%;
   
    background-color: #36b549;

    transition: all 0.3s ease-in-out;
    z-index: 9999;
  }
}

@media (max-width: 767px) {
  .navlinks .nav-link {
    color: #fff;
    padding: 10px;
  }
}

.navbar-expand-lg .navbar-collapse.show {
  left: 0;
}



.navbar-background {
  background-color: #fff;
}

.navbar-background .container-fluid {
  padding-left: 0px;
}

.drop-box .dropdown-menu {

  border: none;
  
  width: 250px;

}

.drop-box .dropdown-menu .dropdown-item {
  margin-left: 0px;
  padding: 20px;
  font-size: 14px;
}

.drop-box .dropdown-menu .dropdown-item:active {

  font-size: 14px;
}


.navbar-background {
  padding: 10px 30px;
}

.navbar {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0px;
}









.carousel-caption {
  background-color: rgba(0, 0, 0, 0.5);
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  display: block;
  height: auto;
  
  max-width: 100%;
}

.image-container .image-text {
 
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.image-text {
  position: relative;
 
}

.image-text {
  text-align: center;
}

@media (max-width: 600px) {
  .image-text {
    padding-bottom: 0px;
  }
}

.image-text img {
  margin: auto;
 
}



.image-text h6 {
  padding-top: 20px;
 
  color: #3ab24a;
 
  font-family: Helvetica Neue Bold;
 
  font-size: 50px;
  
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;
 
}



.image-text h1 {

  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  
  color: #fff;
  
  font-size: 45px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;

  margin: auto;
  font-weight: 600;
  padding: 5px 0px;
  width: 69%;
}

@media (max-width: 600px) {
  .image-text h1 {
    font-size: 15px;
  }
}





.linedown {
  opacity: 0;
  animation: zoom-in 0.5s ease-in-out forwards;

}

@keyframes zoom-in {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}



.carousel-control-prev-icon {
  display: none;
  
}

.carousel-control-next-icon {
  display: none;
  
}



.about p {
 
}

.about h1 {
  font-family: Helvetica Neue Bold;
  
  font-weight: bold;
  font-size: 35px;
  text-align: center;
  color: #3ab24a;
  position: relative;

}

@media (max-width: 600px) {
  .about h1 {
    font-size: 25px;
  }
}

.about h1::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  
  transform: translateX(-50%);
  width: 120px;
  height: 2px;
  background-color: #3ab24a;
}

@media (max-width: 768px) {
  .about h2::after {
    width: 30px;
  }
}

@media (max-width: 600px) {
  .about h2::after {
    width: 60px;
  }
}

@media (max-width: 600px) {
  .about p {
    font-size: 14px;
  }
}



.qualification h1 {
  font-family: Helvetica Neue Bold;
  
  font-weight: bold;
  font-size: 35px;
  text-align: center;
  color: #3ab24a;
  position: relative;

}
@media (max-width: 600px) {
  .qualification h1 {
    font-size: 25px;
  }
}

@media (max-width: 600px) {
  .qualification h2 {
    font-size: 20px;
  }
}

.qualification h1::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 7%;
 
  transform: translateX(-50%);
  width: 120px;
  height: 2px;
  background-color: #3ab24a;
}

@media (max-width:4000px) {
  .qualification h1::after {
    left: 21%;

  }
}

@media only screen and (max-width: 572px) and (min-width: 400px) {
  .qualification h1::after {

    left: 18%;

  }
}

@media only screen and (max-width: 900px) and (min-width: 572px) {
  .qualification h1::after {

    left: 13%;

  }
}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
  .qualification h1::after {

    left: 15%;
  }
}

@media only screen and (max-width: 1349px) and (min-width: 1200px) {
  .qualification h1::after {

    left: 10%;
  }
}







@media (max-width: 600px) {
  .qualification h2::after {
    width: 60px;
  }
}




.about-head-company {
  font-weight: 600;
}

.about-para {
  padding: 15px 0px 0px 0px;
  text-align: center;
}

.para-span {
 
  font-weight: 600;
}

.scroll-top-button {
  position: fixed;
  bottom: 20px;
  right: 5%;
  background: linear-gradient(to bottom, rgb(206, 199, 11) 0%, rgb(0, 135, 56) 100%);
 
  color: #AAE2B3;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  font-size: 24px;
  z-index: 99;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.scroll-top-button:hover {
  background-color: #197B22;
  color: #AAE2B3;

}


@media (max-width: 600px) {
  .about {
    margin: 0px;
  }
}

.about.animate {
  transform: translateY(0);
  opacity: 1;
}

.viewmorebutton {
  text-align: center;
}

.viewmorebutton button {
  background-color: #FFCB05;
  border: #FFCB05;
  border-radius: 30px;
  color: black;
  padding: 5px 30px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 24px 0px;
}

@media (max-width: 600px) {
  .viewmorebutton button {
    font-size: 14px;
  }
}

.viewmorebutton:hover button {
  background-color: #000;
  border: #0000;
  border-radius: 30px;
  color: #FFCB05;
}

.navbar-toggle {

  color: rgb(203 155 104);
}

.parallax {
 
  position: relative;
  perspective: 1px;
}




.parallax__bgone {
 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../assets/oilbannertwo.jpg');
  background-size: cover;
  background-attachment: fixed;
 
}

.parallax__bgone::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.116), rgba(0, 0, 0, 0.164));
}

.parallax__content {
  position: relative;
  z-index: 1;
  padding: 50px 0px;
  text-align: center;
  color: #fff;
}

@media (max-width: 600px) {
  .parallax__content {

    padding: 0px 0px;

  }
}

.box h4 {
  border: 1px solid #fff;
  background: #cb9b68;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 8% 100%);
}

.box {

  background: #cb9b6885
}

.box p {
  text-align: center;
}

.section {
  margin: 40px 0px;
}

@media (max-width: 480px) {
  .section {
    margin: 20px 0px;
  }
}

.products {
  margin: 60px 0px;
}

@media (max-width: 600px) {
  .products {
    margin: 20px 0px;
  }
}

.boxproduct {
 


}

.boxproduct h1 {
  padding: 12px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background: #cb9b68;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 8% 100%);
  border: 2px solid #fff;
}

.boxproduct img {
  transition: transform 0.3s;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 24px 0px;

}

.boxproduct img:hover {
  transform: scale(1.09);
}



.footer {
  right: 0px;
  background-color: #f5f5f5 !important;
  height: 100px;
  width: 100%;

  
}

.about-heade {
  padding: 20px 0px 0px 0px;
}

.about-header h3 {

  color: #cb9b68;
}

@media (max-width: 600px) {
  .about-header h3 {

    font-size: 16px;
  }
}

.logofooter .logo1 {
  width: 50px;
}

.logofooter .logo2 {
  width: 300px;
}

.headoffice li {
  list-style-type: none;
}

.headoffice li h1 {
  font-size: 16px;
  color: #cb9b68;
}

.footerlist {
  padding: 50px 0px 50px 0px;
  background: #387e0dd6;
}

address {
  color: #fff;
}

.headoffice li {
  color: #fff;
}

.footertelephone h6 {
  color: #cb9b68;
}

.footertelephone p {
  color: white;
}

.about-header h6 {
  color: white;
  text-align: center;
}

.whatsappicon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #25d366;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  color: #FFF;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 40px;
  /* z-index: 99; */
  cursor: pointer;
  padding: 12px;
}

.bi-whatsapp {}

.bottomfooter {
 
  background: #316411d6;
}

.bottomfooter p {
  padding: 20px 0px;
  margin-bottom: 0px;
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.about-details {
  padding: 13px 0px;
}

.newsandevents {

  
  margin: 75px 0px 0px 0px;

}

.newsandevents p {
  padding: 80px 80px 80px 80px;
  
  background: #cb9b6880;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 8% 100%);
  color: white;
  text-align: center;
}

.newsandevents .owlclass {
  position: absolute;
  left: 45%;
  top: 32%;
  width: 650px;
}

.news {
  padding: 50px 0px 20px 0px;
}

.quote-head {
  padding: 40px 0px 40px 0px;
}

.menu li {
  list-style: none;
}

.menu li a {
  display: block;
  float: left;
  width: 20%;

  text-align: center;

  font-weight: bold;
  letter-spacing: 1px;
  font-size: 40px;
  color: #fff;
  background: #ccc;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, .25);
  transition: .25s;
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
  .menu li a {
    width: 100%;
  }
}





.menu li a:hover {
  margin: -10px 0 0 0;
}

@media (max-width: 600px) {
  .menu li a {

    width: 100%;
    margin-bottom: 20px;
  }
}




.menu {
  /* width:500px; */
  margin: 60px auto 0 auto;
  border-radius: 10px;
  overflow: hidden;
}

.menu li:first-child a {
  background: #a30e07;
}

.menu li:nth-child(2) a {

  background: #ffe832 !important;
}

.menu li:nth-child(3) a {

  background: #8da45c;

}

.menu li:nth-child(4) a {

  background: #ae6b37;
}

.menu li:nth-child(5) a {

  background: #363b4e;

}

.menu li:first-child:hover~.bg {
  background: #ffca6d;

}

.menu li:nth-child(2):hover~.bg {
  background: #ff6d6d;
}

.menu li:nth-child(3):hover~.bg {
  background: #6dffb9;
}

.menu li:nth-child(4):hover~.bg {
  background: #6dcaff;
}

.menu li:nth-child(5):hover~.bg {
  background: #ec6dff;
}


.menu li a h6 {
  padding: 30px;
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .menu li a h6 {
    padding: 10px;
    font-size: 12px;
  }
}

.about-header h2 {
  font-family: Helvetica Neue Bold;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
  font-weight: bold;
  font-size: 45px;
  text-align: center;
  /* color: #ede7dc; */
  color: #fff;
  position: relative;
  text-transform: uppercase;
  /* text-transform: uppercase;
  font-size: 40px;
  color: #ede7dc;
  padding-top: 32px;
  font-weight: bold; */
}



@media only screen and (max-width: 768px) and (min-width: 576px) {
  .about-header h2 {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .about-header h2 {
    font-size: 12px;
  }
}



.nonoil {
  font-family: Helvetica Neue Bold;
  
  font-weight: bold;
  font-size: 35px;
  text-align: center;
  color: #3ab24a;
  position: relative;
  background: #6f4e2a;
  margin-bottom: 0px;
  color: white;
 
}
@media (max-width: 600px) {
  .nonoil {
    font-size: 25px;
  }
}
.nbox {
  text-align: center;
}

.nbox img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.nbox:hover img {
  opacity: .8;
}


@media (max-width: 480px) {
  .nbox img {
    /* width: 300px; */

  }
}

.newsmain {
  background: #f7ede0;
  padding: 40px 0px;
  
  margin: 20px 0px;

}

.nbox p {
  font-weight: 600;
  
  padding: 5px 0px;
}

.footermain {
  text-align: center;
}

.footermain img {
  height: 40px;
}

.footerlinks {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.footerlinks h6 {
  margin-right: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.socialmedia {
 
}

.socialmedia ul {
  display: flex;
  justify-content: center;
}

.socialmedia ul li {
  margin: 5px;
 
  list-style-type: none;
  
  background: #fbe006;
  
  border-left: 1px solid #589334;
}

.footertelephone {
  color: white;

  font-size: 15px;
}

.footertelephone ul li {
  list-style-type: none;
  font-size: 15px;
  display: flex;
}

.footermain .log {
  height: 105px;
}

.search {
  margin: 10px 0px;
}

.search button {
  background: #c1bea5;
  border-color: #c1bea5;
  color: #fff;
}


.loader-container {
  width: 100%;
  height: 100%;
  position: fixed;

  background: url("../assets/Logo-3D.gif") center no-repeat;
  z-index: 1;
  display: flex;
  background-size: contain;
  justify-content: center;
  align-items: center;
}




.seeproducts {
  background: #a30e07;
  color: aliceblue;
  padding: 6px;
  border: 1px solid #000;
  margin: 10px 0px;
  font-size: 15px;
}

.hide-me {
  visibility: hidden;
  padding: 6px;
  margin: 10px 0px;
}


.inputsearch {
  width: 200px;
}

.carouselitem img {
  margin-top: 83px;
}

@media (max-width: 480px) {
  .carouselitem img {
    width: 60px;

  }
}

.footerdesk ul {
  display: flex;
  width: 100%;
  justify-content: space-around;
  background: aliceblue;
  padding-left: 0px;
  border: 1px solid rgb(222, 223, 224);
  ;
}

.footerdesk ul li {
  list-style-type: none;

}



.nonoilp {

  width: 100%;
  /* position: relative; */

}

.nonoilp .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nonoilp :hover .overlay {
  opacity: 1;
}

.nonoilp .overlay-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: #ffffff;
  
  color: #000000;
 
  border: none;
  cursor: pointer;
}

.overlay-button:hover {
  background-color: #cccccc;
 
}






.quality h1 {


  text-align: center;

  position: relative;
  color: #000;
  font-family: "Colus Regular";
  font-weight: bold;
  font-size: 35px;
}
@media (max-width: 480px) {
  .quality h1 {


    font-size: 25px;
  }
}
.quality h1::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  
  transform: translateX(-50%);
  width: 120px;
  height: 2px;
  background-color: #000;
}

.quality-details h6 {
  font-weight: 600;
}

.quality-details ul li {
  list-style-type: circle;
}

.quality-details ul {
  margin-left: 20px;
}


/* private brands */

.private .carousel-caption {
  position: absolute;
  top: 10%;
  left: 10%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  color: #fff;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 1s ease-in-out;
}

.private .carousel-caption::before {
  content: "";
  position: absolute;

 
  height: 210px;
  width: 8px;
  background-color: #007825;
  transform: translateY(-50%, -50%);
}

.private-brands img {
 
  width: 100%;
  border: 1px solid #d1d2d4
}





@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}



/* certification */

.certification .carousel-caption {
 
  position: absolute;
  top: 15%;
  left: 10%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  color: #fff;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 1s ease-in-out;
}

.certification .carousel-caption::before {
  content: "";
  position: absolute;
  
  height: 70px;
  width: 8px;
  background-color: #007825;
  transform: translateY(-50%, -50%);
}

.certification .carousel-caption h1 {
  padding-left: 15px;
  margin-top: 0px;
  text-align: start;
  font-family: Helvetica Neue Bold;

  font-size: 100px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 80px;
  margin-bottom: 10px;
}

.private-brands img {
  /* width: 216px; */
  border: 1px solid #d1d2d4
}


/* our cerficates */
.companycertificates {
  position: relative;
}

.certify-viewall {
  bottom: -9px;
  position: absolute;
  /* top: 50%; */
  left: 58px;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 600;
}





.certification-about img {
  width: 100%;
}

.companycertificates img {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .companycertificates img {
    padding-bottom: 20px;
  }
}


.certi-div {
  background-color: #ffe3c5;
}

.dropbtn {
  background-color: #fff;
  color: #000;
  padding: 20px 10px;
  font-size: 14px;
  border: none;
  font-weight: 600;
}



@media (max-width: 767px) {
  .dropbtn {
    background: #3ab54a;
    color: white;

  }
}

@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .dropbtn {
    font-size: 8px;
    padding: 20px 4px
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
  .dropbtn {
    font-size: 12px;
    padding: 20px 5px;
  }
}




.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #0e8b35;

  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #3ab54a;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {}

.font-colus {
  font-family: 'colus-regular', sans-serif !important;
}



.product-details img {
  width: 150px;
}

img.detail-view {
  width: 300px;
}

.product-details-heading h1 {
  font-weight: 600;
  margin: 15px 0px;
 
  font-size: 30px;

}

.product-details-heading h4 {
  font-weight: 600;

}

.product-details-heading h6 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;

}

.product-details-heading p {
  font-weight: 600;
 

}

.product-details-brand {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}



.tab-main img {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 24px 0px;
  
}



.tab-main button {
  background-color: #fff;
  border: none;
  
  color: #282828;
 
  font-weight: 600;

  margin: 0px 5px 5px 5px;
  border: 1px solid #ffcb05;
  padding: 6px;
  border-radius: 10px;
}

.button-container {
  margin: 10px 0px;
}

.tab-main button:active {

  color: #fff;

}

.tab-main button:hover {
  color: #fff;
  background: #fccb05;
}

.availablehead {
  font-weight: 600;
  margin: 15px 0px;
  
  font-size: 20px;
}


/* quality */
.mission-quality .carousel-caption {
  position: absolute;
  top: 15%;
  left: 7%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  color: #fff;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 1s ease-in-out;
}

.mission-quality .carousel-caption::before {
  content: "";
  position: absolute;

  height: 320px;
  width: 8px;
  background-color: #fff;
  transform: translateY(-50%, -50%);
}

.mission-quality .carousel-caption h1 {
  
  padding-left: 15px;
  margin-top: 0px;
  text-align: start;
 
  font-size: 100px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 90px;
  margin-bottom: 10px;
  word-spacing: 6px;

  font-family: "Colus Regular";

}

.mission-quality .carousel-caption p {
 
  margin-bottom: 0px;
  text-align: start;
  padding-left: 30px;
}

.mission-about {
  padding: 60px !important;
}

.mission-quality .carousel-control-prev-icon {
  display: none;
}

.mission-quality .carousel-control-next-icon {
  display: none;
}

/* certification */
.certification .carousel-caption {
  position: absolute;
  top: 15%;
  left: 7%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  color: #fff;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 1s ease-in-out;
}

.certification .carousel-caption::before {
  content: "";
  position: absolute;

  height: 180px;
  width: 8px;
  background-color: #fff;
  transform: translateY(-50%, -50%);
}

.certification .carousel-caption h1 {
 
  padding-left: 15px;
  margin-top: 0px;
  text-align: start;
 
  font-size: 100px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 90px;
  margin-bottom: 10px;
  word-spacing: 6px;
  
  font-family: "Colus Regular";

}

.certification .carousel-caption p {
 
  margin-bottom: 0px;
  text-align: start;
  padding-left: 30px;
}

.quality-certi {
  padding-right: 8px !important;
  position: relative;

  display: inline-block;

  transition: opacity 1s ease-in-out;
}

.quality-certi::after {

  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  height: 150px;
  width: 8px;
  background-color: #ffcb05;
  transform: translateY(-50%, -50%);
}

.quality-certi h1 {
  font-weight: bold;
  font-family: "Colus Regular";
  font-size: 35px;
  color: #000;
}

.certi {
  height: 250px;
}


.boardpopup {





  position: fixed;
  top: -5%;
  left: 50%;
 

  margin: auto;
  transform: translate(-50%, -50%);

  padding: 20px;
  
  z-index: 999;

  text-align: center;
  border: none;
  border-radius: 0;
 
  background: linear-gradient(to bottom, #ffcb05, #ffcb05);
  text-align: center;
  border-radius: 10px;
  box-shadow: 8px 10px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 600px) {
  .boardpopup {


    padding: 5px;


  }
}

.boardpopup p {
  border: 4px solid #000;
  padding: 50px;
  margin: 0;
  color: #000;
}

@media screen and (max-width: 600px) {
  .boardpopup p {


    padding: 5px;


  }
}

.boardpopup button {
  margin-top: 10px;
  background-color: #FFCB05;
  border: #FFCB05;
  border-radius: 35px;
  color: #000;
  padding: 5px 15px;
}

.boardpopup button:hover {
  margin-top: 10px;
  background-color: #000;
  border: #000;
  border-radius: 35px;
  color: #FFCB05;
  padding: 5px 15px;
}

.boardpopup .top {
  padding: 2px;
  color: #fff;
  background-color: #000;
 
  font-size: 100px;
  line-height: 170px;
  margin: 30px 0px;
 
}








.boardpopup-data {


  position: fixed;
  top: 50%;
  left: 50%;


 
  transform: translate(-50%, -50%);
  
  padding: 20px;
 
  z-index: 999;

  text-align: center;
  border: none;
  border-radius: 0;
 
  background: linear-gradient(to bottom, #ffcb05, #ffcb05);
  text-align: center;
  border-radius: 10px;
  box-shadow: 8px 10px 10px rgba(0, 0, 0, 0.3);
}


@media screen and (max-width: 600px) {
  .boardpopup-data {


    padding: 5px;


  }
}

.boardpopup-data p {
  border: 4px solid #000;
  padding: 50px;
  margin: 0;
  color: #000;
}

@media screen and (max-width: 600px) {
  .boardpopup-data p {


    padding: 5px;


  }
}

.boardpopup-data button {
  margin-top: 10px;
  background-color: #FFCB05;
  ;
  border: #FFCB05;
  ;
  border-radius: 35px;
  color: #000;
  padding: 5px 15px;
}

.boardpopup-data button:hover {
  margin-top: 10px;
  background-color: #000;
  border: #000;
  border-radius: 35px;
  color: #FFCB05;
  padding: 5px 15px;
}

.boardpopup-data .top {
  padding: 2px;
  color: #fff;
  background-color: #000;
  
  font-size: 100px;
  line-height: 170px;
  margin: 30px 0px;
 
}



.boardpopupnon {
  position: fixed;
  top: 50%;
  left: 50%;
 
  margin: auto;
  transform: translate(-50%, -50%);
 
  padding: 20px;
  
  z-index: 999;

  text-align: center;
  border: none;
  border-radius: 0;
 
  background: linear-gradient(to bottom, #ffcb05, #ffcb05);
  text-align: center;
  border-radius: 10px;
  box-shadow: 8px 10px 10px rgba(0, 0, 0, 0.3);
}

.boardpopupnon p {
  margin: 0;
  color: #000;
}

.boardpopupnon button {
  margin-top: 10px;
  background-color: #000;
  border: #000;
  border-radius: 35px;
  color: #FFCB05;
  padding: 5px 15px;
}

.boardpopupnon button:hover {
  margin-top: 10px;
  background-color: #fff;
  border: #fff;
  border-radius: 35px;
  color: #000;
  padding: 5px 15px;
}

.our-branch .boardpopupnon button {
  margin-top: 10px;
  background-color: #000;
  border: #000;
  border-radius: 35px;
  color: #FFCB05;
  padding: 5px 15px;
}

.head-popup .boardpopupnon button {
  margin-top: 10px;
  background-color: #000;
  border: #000;
  border-radius: 35px;
  color: #FFCB05;
  padding: 5px 15px;
  float: right;
}


.non-popup {
  margin: 10px;
}

.divname:hover .boardpopupnon img {

  opacity: 1;
}

.divname .boardpopupnon:hover img {

  opacity: 1;
}


.sliderone {
  line-height: 80px !important;
  font-size: 45px !important;


}

@media (max-width: 576px) {
  .sliderone {
    line-height: 50px !important;
    font-size: 28px !important;


  }
}

.slidertwo {
  font-size: 65px;
}

@media (max-width: 576px) {
  .slidertwo {
    line-height: 50px !important;
    font-size: 35px !important;


  }
}

.headertop {
  margin-top: 80px;
}

@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .headertop {
    margin-top: 78px;
  }
}

@media only screen and (max-width: 1349px) and (min-width: 1200px) {
  .headertop {
    margin-top: 78px;
  }
}

@media (max-width: 600px) {
  .headertop {
    margin-top: 80px;
  }
}

.pagenotfound {
  text-align: center;
  padding: 50px;
  height: 500px;
  margin: auto;
}

.pagenotfound h1 {
  font-size: 30px;
  color: #000;
}

.video-head h1 {
  background: #ef342b;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 25px;

}



.our-branch-first button {
  background-color: #FFCB05;
  border: #FFCB05;
  border-radius: 30px;
  color: black;
  padding: 2px 25px;
  font-size: 14px;
  font-weight: 600;
  bottom: 18px;
  right: 20px;

}

.productfree h2 {
  animation: 2s anim-lineUp ease-out infinite;
  margin-bottom: 10px;
  margin-top: 10px;

}

.rise {

  text-shadow: -0.01em -0.01em 0.01em #000;
  animation: rise 2s ease-in-out 0.5s forwards;
  color: #ef342b;
  font-weight: 600;
  font-size: 40px;
  font-family: 'colus-regular';

}

@keyframes rise {
  to {
    text-shadow: 0em 0.01em #ff5, 0em 0.02em #ff5, 0em 0.02em 0.03em #ff5,
      -0.01em 0.01em #333, -0.02em 0.02em #333, -0.03em 0.03em #333,
      -0.04em 0.04em #333, -0.01em -0.01em 0.03em #000, -0.02em -0.02em 0.03em #000,
      -0.03em -0.03em 0.03em #000;
    transform: translateY(-0.025em) translateX(0.04em);
  }
}


.image-board {
  position: relative;
  display: inline-block;
}

@media screen and (max-width: 600px) {
  .image-board {
    padding: 10px;
  }
}

.box {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
}

.box-1 {
  top: 10px;
  left: 10px;
}

.box-2 {
  top: 10px;
  right: 10px;
}

.box-3 {
  bottom: 10px;
  left: 10px;
}

.box-4 {
  bottom: 10px;
  right: 10px;
}

.box-5 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box-6 {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.careersmain-head h1 {
  font-weight: bold;
  font-family: "Colus Regular";
  font-size: 50px;
  color: #000;
  margin-bottom: 0px;
}
@media screen and (max-width: 600px) {
  .careersmain-head h1 {
    font-size: 30px;
  }
}
.careers-opening h3 {
  padding: 5px;
  font-family: "Colus Regular";
  font-size: 35px;
  color: #FFF;
  background-color: #ef342b;
}

.careers-opening {
  width: fit-content;
}

.careersmain-head address {
  color: #000000;
}

.address-head {

  font-family: "Colus Regular";
  font-size: 25px;

  margin-bottom: 0px;
}
@media screen and (max-width: 576px) {
  .address-head  {
    font-size: 15px;
  }
}
.address-headname {
  font-weight: bold;
  font-size: 20px;

  margin-bottom: 0px;
}
@media screen and (max-width: 576px) {
  .address-headname  {
    font-size: 15px;
  }
}
.nonoil-product {
  margin-bottom: 20px;
}

.fixedlogo {
  position: absolute;
  top: 0%;
  right: -6%;


  border: none;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  z-index: 99;
}

.fixedlogo img {
  width: 78px;
  padding: 5px;

}

.gallery-image {
  margin: 40px 0px;
}

.gallery-image img {

  padding: 15px;
}

.gallery-first {
  position: relative;
}

.gallery-enquiry {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.gallery-enquiry h1 {
  font-size: 32px;
  color: #fff;
  font-weight: 900;
  font-family: "Colus Regular";
}

@media (max-width: 767px) {
  .gallery-enquiry h1 {
    font-size: 25px;

  }

}


.gallery-enquiry button {
  background-color: #FFCB05;
  border: #FFCB05;
  border-radius: 30px;
  color: black;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 24px 0px;
}
@media (max-width:576px) {
  .gallery-enquiry button {
    
    padding: 5px 10px;
    font-size: 10px;

  }

}

.video-head h6 {
  background: #ef342b;
  color: white;
  text-align: center;
  align-items: center;
  padding: 4px;
  font-size: 14px;
  height: 60px;

}

.video-bottom {
  

  margin: 10px 10px;
}

.video-bottom img {
  width: 100%;
  height: 340px;

}

.langbutton {
  border: none;
  background: #3ab44a;
  color: white;
}

.ceoimage {
  position: relative;
}

.ceomessage {
  width: 40%;
  position: absolute;
  top: 25%;
  left: 20%;

}

.ceomessage h1 {
  font-weight: bold;
  font-family: "Colus Regular";
  font-size: 30px;
  color: #000;
}

.board-img {
  position: relative;
}

.board-img h2 {
  font-size: 20px;
  width: 100%;
  color: #000;
  position: absolute;
  top: 78%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 900;
  padding: 25px;
}

.board-img h6 {
  font-size: 15px;
  width: 100%;
  color: #69615f;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
}

@media (max-width: 620px) {
  .board-img h2 {
    font-size: 10px;
    padding: 10px;

  }
}

@media (max-width: 620px) {
  .board-img h6 {
    font-size: 10px;
    padding: 10px;

  }
}

.ceomessage p {
  line-height: 18px;
  font-family: 'PT Serif', serif;
}

.addressspan {
  font-weight: bold;
  line-height: 15px;
  font-size: 12px;
  list-style-type: none;
}

.carousel-gallery {

  width: 100%;
  color: #69615f;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
}

.carousel-gallery h1 {
  font-size: 90px;
}

@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .carousel-gallery h1 {
    font-size: 75px;
  }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .carousel-gallery h1 {
    font-size: 65px;
  }
}

@media (max-width: 600px) {
  .carousel-gallery h1 {
    font-size: 40px;
  }
}



.archive-box {
  padding: 40px 0px;
}

.archive-box img {
  width: 100%;
  cursor: pointer;
}

.archive-box h1 {
  font-size: 20px;
  text-align: center;
  padding: 5px 0px;
}

.media-news {
  height: 352px;


  padding: 10px;
  margin: 20px 20px 20px 20px;
  background: #dfdfdf;
  align-items: center;
}

@media (max-width: 1200px) {
  .media-news {
    height: auto;


  }
}


.media-news-link {
  height: 390px;


  padding: 10px;
  margin: 20px 20px 20px 20px;
  background: #dfdfdf;
  align-items: center;
}

.media-news-link img {
  width: 100%;
}


.media-news h6 {
  font-size: 15px;
  color: #000;
  padding: 0px 0px 10px 0px;
}

.media-news img {
  width: 100%;
}

.media-news:hover {
  opacity: .8;
}

.media-news-news {
  height: 290px;


  padding: 10px;

  background: #dfdfdf;
  align-items: center;
}

.media-news-news h6 {
  font-size: 15px;
  color: #000;
  padding: 0px 0px 10px 0px;
}

.media-news-news img {
  width: 100%;
}

.media-news-news:hover {
  opacity: .8;
}




.ourproductpara {
  font-size: 12px;
}

.board-para-new p {
  font-weight: bold;
  color: #000;
  font-family: "Colus Regular";
  line-height: 35px;
  font-size: 20px;
  text-align: center;
  padding: 40px 0px;
}
@media (max-width: 576px) {
  .board-para-new p {
    font-weight: bold;
    color: #000;
    font-family: "Colus Regular";
    line-height: 25px;
    font-size: 15px;
    text-align: center;
    padding: 40px 0px;
  }
}

.image-container {
  cursor: pointer;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(205, 205, 205);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: max-content;
  padding: 15px;

  margin: 200px auto 200px auto;
  height: 400px;
}


@media only screen and (max-width: 600px) and (min-width: 400px) {
  .modal {
    width: 100%;
    height: 440px;

  }
}

@media only screen and (max-width: 400px) {
  .modal {
    width: 100%;
    height: 350px;

  }
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal img {
  
  width: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  padding: 2px 10px;
  border: none;
  color: white;
  font-size: 10px;
  cursor: pointer;
  background: black;
  border-radius: 30px;
}

/* IframeComponent.css */
.iframe-container {
  width: auto;
  
  max-width: 100%;
  
}

.auto-width-iframe {
 
}

@media (max-width: 576px) {
  .auto-width-iframe {
    width: max-content;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {

  .health-res dl,
  ol,
  ul {
    font-size: 13px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {

  .health-res dl,
  ol,
  ul {
    font-size: 11px;
    line-height: 15px;
  }
}

@media only screen and (max-width: 767px) {

  .carousel-indicators {
    display: none;
  }
}

.rtl-text {
  direction: rtl;
}
.ltr-text {
direction: ltr;
}





