@font-face {
  font-family: 'Colus Regular';
  src: url('../fonts/FontsFree-Net-Colus-Regular.ttf') format('truetype');



}

@font-face {
  font-family: 'TheSans';
  src: url('../fonts/TheSans\ Plain.ttf') format('truetype');




}

@font-face {
  font-family: 'TheSansBold';
  src: url('../fonts/TheSans\ Bold.otf') format('truetype');




}