.board-of-directors {
    position: relative;
}
.board-of-directors img{
    width: 100%;
}
@media screen and (max-width: 600px) {
    .board-of-directors img{
        /* width: auto; */
    height: 212px;
    }
  }
  @media screen and (max-width: 1200px) and (min-width: 600px)  {
    .board-of-directors img{
        /* width: auto; */
    height: 212px;
    }
  }
.borad-para {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.borad-para p {

    font-weight: bold;
    color: #000;
    font-family: "Colus Regular";
    line-height: 35px;
    font-size: 20px;


  
    text-align: justify;

}
@media screen and (max-width: 991px) and (min-width: 600px)  {
    .borad-para p {

   
        font-size:15px;
    
    
    
    }
  }
@media screen and (max-width: 600px) {
    .borad-para p{
        font-size: 13px;
    }
  }
.client {
    position: relative;
    display: inline;
}
.client img{
    width: 100%;
  
}
.client-banner{
    height: 950px;
}


@media only screen and (max-width: 1400px) and (min-width: 1200px) {
    .client-banner{
        height: 950px;
        
    }
  }
  
  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    .client-banner {
        height: 950px;
     
    }
  }
  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    .divname {
      padding: 10px;
     
    }
  }
  @media only screen and (max-width: 992px) and (min-width: 768px) {
    .client-banner{
      height:1400px;
    }
  }
  @media only screen and (max-width: 992px) and (min-width:768px) {
    .divname {
      padding: 10px;
     
    }
  }
  @media only screen and (max-width: 768px) and (min-width: 576px) {
    .client-banner{
      height:1100px;  
    }
  }
  @media only screen and (max-width: 798px) and (min-width: 576px) {
    .divname {
      padding: 10px;
     
    }
  }
  @media only screen and (max-width: 576px) and (min-width: 400px) {
    .divname {
      padding: 10px;
     
    }
  }
  @media (max-width: 460px) {
    .client-banner {
      height:840px;
    }
  }

  

/* @media screen and (max-width: 991px) and (min-width: 600px)  {
    .client-banner{
        height: 1300px;
    }
  }
  @media screen and (max-width: 600px) and (min-width: 460px)  {
    .client-banner{
        height: 1050px;
    }
  }
@media screen and (max-width: 460px) {
    .client-banner{
        height: 850px;
    }
  } */




.clienimages {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgba(0, 0, 0, 0.5); */
    width: 100%;
    color: white;
    /* padding: 10px; */
}
@media screen and (max-width: 600px) {
    .clienimages img {
        /* width: 92px; */
    
    }
  }



.clienimages img {
    /* width: 320px; */

}
@media screen and (max-width: 600px) {
    .clienimages img {
        /* width: 92px; */
    
    }
  }

.divname {
    text-align: center;
    /* margin-bottom: 60px; */
    /* margin: 20px; */
    /* width: 200px; */
}
.boardsecond{
    /* margin-bottom: 40px; */
}